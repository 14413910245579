import React, { createContext, useState, useContext } from "react";
import { AuthContext } from "../State/AuthContext";
import { useNavigate } from "react-router-dom";
import { loginUser, registerTempUser, createNewUser, forgotPassword, resetPassword } from "../Api/apiService"; // Assuming resetPassword API

export const AuthFunctionsContext = createContext();

// export const useAuthfns = () => {
//     return useContext(AuthFunctionsContext);
// };

export const AuthFunctionsProvider = ({ children }) => {
    const { setEmail, setAccessToken, setRefreshToken, setProfileName, setProfileRole, setOrgDomain, setSubscriptionType } = useContext(AuthContext);

    // States for the form
    const [username, setUsername] = useState("");
    const [password, setPassword] = useState("");
    const [firstName, setFirstName] = useState("");
    const [lastName, setLastName] = useState("");
    const [companyName, setCompanyName] = useState("");
    const [jobTitle, setJobTitle] = useState("");
    const [confirmPassword, setConfirmPassword] = useState("");
    const [isSignup, setIsSignup] = useState(false); // Track whether it's signup or login
    const [isForgotPassword, setIsForgotPassword] = useState(false); // Track forgot password view
    const [otp, setOtp] = useState("");
    const [invitationId, setInvitationId] = useState(""); // Store invitation_id returned from the signup API
    const [signupStep, setSignupStep] = useState(1); // Step 1: Email/Name, Step 2: OTP/Password
    const [forgotPasswordStep, setForgotPasswordStep] = useState(1); // 1 for email input, 2 for OTP and new password
    const [authStatus, setAuthStatus] = useState(""); // 1 for email input, 2 for OTP and new password

    const navigate = useNavigate();

    const [error, setError] = useState({
        username: "",
        password: "",
        confirmPassword: "",
        firstName: "",
        lastName: "",
        authfail: "",
        companyName: "",
        jobTitle: ""
    });
    const [loadingAuth, setLoadingAuth] = useState(false);

    const sb_auth_validate_email = (email) => {
        const regex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
        return regex.test(email);
    };

    const sb_auth_handleBlur = (e) => {
        const { id, value } = e.target;

        if (id === "email") {
            if (!value) {
                setError((prevState) => ({ ...prevState, username: "Email is required." }));
            } else if (!sb_auth_validate_email(value)) {
                setError((prevState) => ({ ...prevState, username: "Please enter a valid email address." }));
            } else {
                setError((prevState) => ({ ...prevState, username: "" }));
            }
        }

        if (id === "password") {
            if (!value) {
                setError((prevState) => ({ ...prevState, password: "Password is required." }));
            } else {
                setError((prevState) => ({ ...prevState, password: "" }));
            }
        }

        if (id === "firstName") {
            if (!value) {
                setError((prevState) => ({ ...prevState, firstName: "First name is required." }));
            } else {
                setError((prevState) => ({ ...prevState, firstName: "" }));
            }
        }

        if (id === "lastName") {
            if (!value) {
                setError((prevState) => ({ ...prevState, lastName: "Last name is required." }));
            } else {
                setError((prevState) => ({ ...prevState, lastName: "" }));
            }
        }

        if (id === "companyName") {
            if (!value) {
                setError((prevState) => ({ ...prevState, companyName: "Company name is required." }));
            } else {
                setError((prevState) => ({ ...prevState, companyName: "" }));
            }
        }

        if (id === "jobTitle") {
            if (!value) {
                setError((prevState) => ({ ...prevState, jobTitle: "Job Title is required." }));
            } else {
                setError((prevState) => ({ ...prevState, jobTitle: "" }));
            }
        }

        if (id === "confirmPassword") {
            if (value !== password) {
                setError((prevState) => ({ ...prevState, confirmPassword: "Passwords do not match." }));
            } else {
                setError((prevState) => ({ ...prevState, confirmPassword: "" }));
            }
        }

        if (id === "otp") {
            if (!value) {
                setError((prevState) => ({ ...prevState, otp: "OTP is required." }));
            } else {
                setError((prevState) => ({ ...prevState, otp: "" }));
            }
        }
    };

    const sb_auth_handleLogin = async (e) => {
        e.preventDefault();
        setError({}); // Clear previous errors

        // Basic validation for login
        if (!username || !password || error.username || error.password) {
            return; // Don't submit if any validation error exists
        }

        setLoadingAuth(true); // Start loading

        try {
            const loginResponse = await loginUser(username, password); // Call login API

            if (loginResponse?.access_token) {
                localStorage.setItem("access_token", loginResponse.access_token);
                setAccessToken(loginResponse.access_token);
            }

            if (loginResponse?.refresh_token) {
                localStorage.setItem("refresh_token", loginResponse.refresh_token);
                setRefreshToken(loginResponse.refresh_token);
            }

            if (loginResponse?.name) {
                setProfileName(loginResponse.name);
            }

            if (loginResponse?.roles) {
                setProfileRole(loginResponse.roles);
            }

            if (loginResponse?.domain) {
                setOrgDomain(loginResponse.domain);
            }

            if (loginResponse?.subscription_type) {
                setSubscriptionType(loginResponse.subscription_type);
            }

            setEmail(username);
            navigate("/jobs-library");

        } catch (err) {
            setError({ authfail: "Incorrect username or password. Please try again!" });
        } finally {
            setLoadingAuth(false); // Stop loading after the request completes
        }
    };

    const sb_auth_handleSignupStep1 = async (e) => {
        e.preventDefault();
        setError({}); // Clear previous errors

        // Basic validation for step 1
        if (!username || !firstName || !lastName || !companyName || !jobTitle || error.username) {
            return; // Don't submit if any validation error exists
        }

        setLoadingAuth(true); // Start loading

        try {
            // Send OTP API Call
            const signUpResponse1 = await registerTempUser(firstName, lastName, username); // sends the OTP and returns the invitation_id
            if (signUpResponse1?.invitation_id) {
                console.log(signUpResponse1.invitation_id);
                setAuthStatus("OTP has been successfully sent to your email address.");
                setInvitationId(signUpResponse1.invitation_id); // Store invitation_id for step 2
                setSignupStep(2);
            } else {
                setError({ authfail: "Signup unsuccessful. Please check the information you've entered and try again!" });
            }
        } catch (err) {
            setError({ authfail: "Signup unsuccessful. Please check the information you've entered and try again!" });
        } finally {
            setLoadingAuth(false); // Stop loading after the request completes
        }
    };

    // Signup function for step 2 (after OTP is sent)
    const sb_auth_handleSignupStep2 = async (e) => {
        e.preventDefault();
        setError({}); // Clear previous errors

        // Basic validation for step 2
        if (!otp || !password || password !== confirmPassword || error.otp || error.password || error.confirmPassword) {
            return; // Don't submit if any validation error exists
        }

        setLoadingAuth(true); // Start loading

        try {
            // Step 2 API call for signup (including invitation_id, OTP, and Password)
            const signUpResponse2 = await createNewUser(invitationId, otp, password); // Assuming signupWithOtpAndPassword is the API to call
            console.log(signUpResponse2);
            if (signUpResponse2?.message) {
                setAuthStatus(signUpResponse2.message)
                toggleSignup();
            }

        } catch (err) {
            setError({ authfail: "Signup unsuccessful. Please check the information you've entered and try again!" });
        } finally {
            setLoadingAuth(false); // Stop loading after the request completes
        }
    };


    // Function to send OTP for forgot password
    const sendOtpToEmail = async (username) => {
        setLoadingAuth(true);
        try {
            const forgotPasswordResponse1 = await forgotPassword(username);
            console.log(forgotPasswordResponse1);
            if (forgotPasswordResponse1?.message) {
                // Success: You can set a success message or handle UI changes here
                setAuthStatus(forgotPasswordResponse1?.message);
                setLoadingAuth(false);
                setForgotPasswordStep(2);
            } else {
                setError({ authfail: "Failed to send OTP. Try again!" });
                setLoadingAuth(false);
            }
        } catch (err) {
            console.error("Error sending OTP:", err);
            setError({ authfail: "Error sending OTP. Please try again!" });
            setLoadingAuth(false);
        }
    };

    // Function to verify OTP and reset password
    const verifyOtpAndResetPassword = async (username, otp, newPassword, confirmNewPassword) => {
        setLoadingAuth(true);
        if (newPassword !== confirmNewPassword) {
            setError({ password: "Passwords do not match!" });
            setLoadingAuth(false);
            return;
        }

        try {
            const forgotPasswordResponse2 = await resetPassword(username, otp, newPassword);
            console.log(forgotPasswordResponse2);
            if (forgotPasswordResponse2?.message) {
                setAuthStatus(forgotPasswordResponse2?.message);
                setLoadingAuth(false);
                setIsForgotPassword(false);
            } else {
                setError({ authfail: "Invalid OTP or error resetting password!" });
                setLoadingAuth(false);
            }
        } catch (err) {
            console.error("Error verifying OTP:", err);
            setError({ authfail: "An error occurred while resetting your password." });
            setLoadingAuth(false);
        }
    };

    const toggleSignup = () => setIsSignup((prev) => !prev, setError({}));
    const toggleForgotPassword = () => setIsForgotPassword((prev) => !prev, setError({}));

    return (
        <AuthFunctionsContext.Provider
            value={{
                username,
                setUsername,
                password,
                setPassword,
                firstName,
                setFirstName,
                lastName,
                setLastName,
                confirmPassword,
                setConfirmPassword,
                error,
                setError,
                loadingAuth,
                sb_auth_handleLogin,
                sb_auth_handleSignupStep1,
                sb_auth_handleSignupStep2,
                sb_auth_handleBlur,
                sendOtpToEmail,
                verifyOtpAndResetPassword,
                isSignup,
                toggleSignup,
                isForgotPassword,
                toggleForgotPassword,
                otp,
                setOtp,
                signupStep,
                setSignupStep,
                forgotPasswordStep,
                setForgotPasswordStep,
                authStatus,
                setAuthStatus,
                companyName,
                setCompanyName,
                jobTitle,
                setJobTitle
            }}
        >
            {children}
        </AuthFunctionsContext.Provider>
    );
};

//export default AuthFunctionsProvider;
