import React, { useContext } from "react";
import { AuthFunctionsContext } from "../../State/AuthFunctionsContext";

const SbSignupComponent = () => {
  const {
    username,
    setUsername,
    password,
    setPassword,
    confirmPassword,
    setConfirmPassword,
    firstName,
    setFirstName,
    lastName,
    setLastName,
    otp,
    setOtp,
    error,
    sb_auth_handleBlur,
    loadingAuth,
    sb_auth_handleSignupStep1,
    sb_auth_handleSignupStep2,
    toggleSignup,
    signupStep,
    companyName,
    setCompanyName,
    jobTitle,
    setJobTitle
  } = useContext(AuthFunctionsContext);


  const handleNextStep = async (e) => {
    e.preventDefault();
    // Validate and send OTP in Step 1
    if (!firstName || !lastName || !username || !companyName || !jobTitle) {
      return; // Don't go to Step 2 if validation fails
    }
    await sb_auth_handleSignupStep1(e); // Send OTP to email
  };

  const handleSignup = async (e) => {
    e.preventDefault();
    // Validate OTP and password before signup
    if (!otp || !password || password !== confirmPassword) {
      return; // Validation fails, don't proceed
    }

    sb_auth_handleSignupStep2(e); // Final submit for signup
  };

  return (
    <>
      {signupStep === 1 ? (
        <form onSubmit={handleNextStep}>
          <div className="mb-3">
            <label htmlFor="firstName" className="form-label w-100 text-start">
              First Name
            </label>
            <input
              type="text"
              className="form-control"
              id="firstName"
              value={firstName}
              onBlur={sb_auth_handleBlur}
              onChange={(e) => setFirstName(e.target.value)}
              required
            />
            {error.firstName && (
              <p className="text-start mt-1 error-message text-danger">{error.firstName}</p>
            )}
          </div>

          <div className="mb-3">
            <label htmlFor="lastName" className="form-label w-100 text-start">
              Last Name
            </label>
            <input
              type="text"
              className="form-control"
              id="lastName"
              value={lastName}
              onBlur={sb_auth_handleBlur}
              onChange={(e) => setLastName(e.target.value)}
              required
            />
            {error.lastName && (
              <p className="text-start mt-1 error-message text-danger">{error.lastName}</p>
            )}
          </div>

          <div className="mb-3">
            <label htmlFor="companyName" className="form-label w-100 text-start">
              Company Name
            </label>
            <input
              type="text"
              className="form-control"
              id="companyName"
              value={companyName}
              onBlur={sb_auth_handleBlur}
              onChange={(e) => setCompanyName(e.target.value)}
              required
            />
            {error.firstName && (
              <p className="text-start mt-1 error-message text-danger">{error.companyName}</p>
            )}
          </div>

          <div className="mb-3">
            <label htmlFor="jobTitle" className="form-label w-100 text-start">
              Job Title
            </label>
            <input
              type="text"
              className="form-control"
              id="jobTitle"
              value={jobTitle}
              onBlur={sb_auth_handleBlur}
              onChange={(e) => setJobTitle(e.target.value)}
              required
            />
            {error.jobTitle && (
              <p className="text-start mt-1 error-message text-danger">{error.jobTitle}</p>
            )}
          </div>

          <div className="mb-3">
            <label htmlFor="email" className="form-label w-100 text-start">
              Employee Email
            </label>
            <input
              type="email"
              className="form-control"
              id="email"
              value={username}
              onBlur={sb_auth_handleBlur}
              onChange={(e) => setUsername(e.target.value)}
              required
            />
            {error.username && (
              <p className="text-start mt-1 error-message text-danger">{error.username}</p>
            )}
          </div>

          {error.authfail && <p className="text-start mt-1 error-message text-danger">{error.authfail}</p>}

          {/* Send OTP button */}
          {loadingAuth ? (
            <div className="d-flex justify-content-center mb-3">
              <div className="spinner-border" role="status">
                <span className="sr-only"></span>
              </div>
            </div>
          ) : (
            <button type="submit" className="btn btn-primary w-100 btn-lg mb-3">
              Send OTP
            </button>
          )}
        </form>
      ) : (
        <form onSubmit={handleSignup}>
          <div className="mb-3">
            <label htmlFor="otp" className="form-label w-100 text-start">
              OTP
            </label>
            <input
              type="text"
              className="form-control"
              id="otp"
              value={otp}
              onChange={(e) => setOtp(e.target.value)}
              onBlur={sb_auth_handleBlur}
              required
            />
            {error.otp && <p className="text-start mt-1 error-message text-danger">{error.otp}</p>}
          </div>

          <div className="mb-3">
            <label htmlFor="password" className="form-label w-100 text-start">
              Password
            </label>
            <input
              type="password"
              className="form-control"
              id="password"
              value={password}
              onBlur={sb_auth_handleBlur}
              onChange={(e) => setPassword(e.target.value)}
              required
            />
            {error.password && (
              <p className="text-start mt-1 error-message text-danger">{error.password}</p>
            )}
          </div>

          <div className="mb-3">
            <label htmlFor="confirmPassword" className="form-label w-100 text-start">
              Confirm Password
            </label>
            <input
              type="password"
              className="form-control"
              id="confirmPassword"
              value={confirmPassword}
              onBlur={sb_auth_handleBlur}
              onChange={(e) => setConfirmPassword(e.target.value)}
              required
            />
            {error.confirmPassword && (
              <p className="text-start mt-1 error-message text-danger">{error.confirmPassword}</p>
            )}
          </div>

          {error.authfail && <p className="text-start mt-1 error-message text-danger">{error.authfail}</p>}

          {/* Show the loader while signup is in progress */}
          {loadingAuth ? (
            <div className="d-flex justify-content-center mb-3">
              <div className="spinner-border" role="status">
                <span className="sr-only"></span>
              </div>
            </div>
          ) : (
            <button type="submit" className="btn btn-primary w-100 btn-lg mb-3">
              Sign Up
            </button>
          )}
        </form>
      )}

      {/* Link for switching to Login page */}
      <div className="text-start p-3">
        Already have an account?{" "}
        <a onClick={toggleSignup} className="text-dark">
          Sign In!
        </a>
      </div>
    </>
  );
};

export default SbSignupComponent;
