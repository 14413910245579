import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { AuthProvider } from "../src/State/AuthContext";
import HomePage from "./Pages/HomePage/HomePage";
import Dashboard from "./Pages/Dashboard/Dashboard";
import JobsLibrary from "./Pages/JobsLibrary/SbJobsLibrary";
import SkillsBridger from "./Pages/SkillsBridger/SkillsBridger";
import LearningCatalyst from "./Pages/LearningCatalyst/LearningCatalyst";
import TalentMobility from "./Pages/TalentMobility/TalentMobility";
import "./App.css";
import './styles.scss';

function App() {
  return (
    <Router>
      <AuthProvider>
        <Routes>
          <Route
            path="/"
            element={
              <HomePage />
            }
          >
            <Route path="/dashboard" element={<Dashboard />} />
            <Route path="/jobs-library" element={<JobsLibrary />} />
            <Route path="/Skills-bridger" element={<SkillsBridger />} />
            <Route path="/learning-catalyst" element={<LearningCatalyst />} />
            <Route path="/talent-mobility" element={<TalentMobility />} />
          </Route>

          {console.log("SSO-ClientID-" + process.env.REACT_APP_SSO_CLIENT_ID)}
          {console.log("env-" + process.env.NODE_ENV)}
          {console.log("base url-" + process.env.REACT_APP_API_BASE_URL)}
          {console.log("base url-" + process.env.REACT_APP_AUTH_BASE_URL)}
        </Routes>
      </AuthProvider>
    </Router>

  );
}

export default App;
