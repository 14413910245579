import React, { useContext } from "react";
import { AuthFunctionsContext } from "../../State/AuthFunctionsContext";
import "./SbLoginComponent.scss";

const SbLoginComponent = () => {
  const {
    username,
    setUsername,
    password,
    setPassword,
    error,
    sb_auth_handleLogin,
    sb_auth_handleBlur,
    loadingAuth,
    toggleSignup,
    toggleForgotPassword,
    authStatus,
  } = useContext(AuthFunctionsContext);

  const handleForgotPasswordClick = (e) => {
    e.preventDefault();
    toggleForgotPassword(); // Toggle to forgot password view
  };


  return (
    <>
      {authStatus && (
        <div className="text-center p-3 text-success">
          {authStatus}
        </div>
      )}

      <form onSubmit={sb_auth_handleLogin}>
        <div className="mb-3">
          <label htmlFor="email" className="form-label w-100 text-start">
            Employee Email
          </label>
          <input
            type="email"
            className="form-control"
            id="email"
            value={username}
            onBlur={sb_auth_handleBlur}
            onChange={(e) => setUsername(e.target.value)}
            required
          />
          {error.username && (
            <p className="text-start mt-1 error-message text-danger">{error.username}</p>
          )}
        </div>

        <div className="mb-3">
          <label htmlFor="password" className="form-label w-100 text-start">
            Password
          </label>
          <input
            type="password"
            className="form-control"
            id="password"
            value={password}
            onBlur={sb_auth_handleBlur}
            onChange={(e) => setPassword(e.target.value)}
            required
          />
          {error.password && (
            <p className="text-start mt-1 error-message text-danger">{error.password}</p>
          )}
        </div>
        <div className="text-end p-3">
          <a onClick={handleForgotPasswordClick} className="text-dark">
            Forgot password?
          </a>
        </div>

        {error.authfail && (
          <p className="text-start mt-1 error-message text-danger">{error.authfail}</p>
        )}

        {/* Show the loader while login is in progress */}
        {loadingAuth ? (
          <div className="d-flex justify-content-center mb-3">
            <div className="spinner-border" role="status">
              <span className="sr-only"></span>
            </div>
          </div>
        ) : (
          <button type="submit" className="btn btn-primary w-100 btn-lg mb-3">
            Sign in
          </button>
        )}
      </form>

      <div className="text-start p-3">
        <span>Don't have an account?</span>{" "}
          <a onClick={toggleSignup} className="text-dark">
            Sign up!
          </a>
      </div>
    </>
  );
};

export default SbLoginComponent;
