import React, {useContext } from "react";
import { AuthFunctionsContext } from "../../State/AuthFunctionsContext";

const SbForgotPasswordComponent = () => {
    const {
        username,
        setUsername,
        otp,
        setOtp,
        password,
        setPassword,
        confirmPassword,
        setConfirmPassword,
        error,
        sendOtpToEmail, // Function to send OTP
        verifyOtpAndResetPassword, // Function to verify OTP and reset password
        loadingAuth,
        toggleForgotPassword,
        sb_auth_handleBlur,
        forgotPasswordStep,

    } = useContext(AuthFunctionsContext);


    const handleEmailSubmit = async (e) => {
        e.preventDefault();
        await sendOtpToEmail(username); // Send OTP to the email
    };

    const handleResetPasswordSubmit = (e) => {
        e.preventDefault();
        verifyOtpAndResetPassword(username, otp, password, confirmPassword);
    };

    return (
        <>
            {forgotPasswordStep === 1 ? (
                // Step 1: Email input to send OTP
                <form onSubmit={handleEmailSubmit}>
                    <div className="mb-3">
                        <label htmlFor="email" className="form-label w-100 text-start">
                            Employee Email
                        </label>
                        <input
                            type="email"
                            className="form-control"
                            id="email"
                            value={username}
                            onChange={(e) => setUsername(e.target.value)}
                            onBlur={sb_auth_handleBlur}
                            required
                        />
                        {error.username && <p className="text-start mt-1 error-message text-danger">{error.username}</p>}
                    </div>

                    {error.authfail && <p className="text-start mt-1 error-message text-danger">{error.authfail}</p>}

                    {loadingAuth ? (
                        <div className="d-flex justify-content-center mb-3">
                            <div className="spinner-border" role="status">
                                <span className="sr-only"></span>
                            </div>
                        </div>
                    ) : (
                        <button type="submit" className="btn btn-primary w-100 btn-lg mb-3">
                            Send OTP
                        </button>
                    )}
                </form>
            ) : (
                // Step 2: OTP and new password input
                <form onSubmit={handleResetPasswordSubmit}>
                    <div className="mb-3">
                        <label htmlFor="otp" className="form-label w-100 text-start">
                            Enter OTP
                        </label>
                        <input
                            type="text"
                            className="form-control"
                            id="otp"
                            value={otp}
                            onChange={(e) => setOtp(e.target.value)}
                            onBlur={sb_auth_handleBlur}
                            required
                        />
                        {error.otp && <p className="text-start mt-1 error-message text-danger">{error.otp}</p>}
                    </div>

                    <div className="mb-3">
                        <label htmlFor="password" className="form-label w-100 text-start">
                            New Password
                        </label>
                        <input
                            type="password"
                            className="form-control"
                            id="password"
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                            onBlur={sb_auth_handleBlur}
                            required
                        />
                        {error.password && <p className="text-start mt-1 error-message text-danger">{error.password}</p>}
                    </div>

                    <div className="mb-3">
                        <label htmlFor="confirmPassword" className="form-label w-100 text-start">
                            Confirm New Password
                        </label>
                        <input
                            type="password"
                            className="form-control"
                            id="confirmPassword"
                            value={confirmPassword}
                            onChange={(e) => setConfirmPassword(e.target.value)}
                            onBlur={sb_auth_handleBlur}
                            required
                        />
                        {error.confirmPassword && <p className="text-start mt-1 error-message text-danger">{error.confirmPassword}</p>}
                    </div>

                    {error.authfail && <p className="text-start mt-1 error-message text-danger">{error.authfail}</p>}

                    {loadingAuth ? (
                        <div className="d-flex justify-content-center mb-3">
                            <div className="spinner-border" role="status">
                                <span className="sr-only"></span>
                            </div>
                        </div>
                    ) : (
                        <button type="submit" className="btn btn-primary w-100 btn-lg mb-3">
                            Reset Password
                        </button>
                    )}
                </form>
            )}

            <div className="text-start p-3">
                Remember your password?{" "}
                <a onClick={toggleForgotPassword} className="text-dark">
                    Go back to Login
                </a>
            </div>
        </>
    );
};

export default SbForgotPasswordComponent;
